import React from 'react';
import {
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="February 2023"
      subnav="release-notes">
      <div id="february2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Not much in terms of updates this month, but plenty of work for
          Uniform Version 5 is happening behind the scenes!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.28.1"
              versionUrl="https://github.com/hudl/uniform.hudl.com"
            />
            <div className={styles.changesList}>
              <Fixed>
                The <Link href="/components/forms/select/code">Select</Link>{' '}
                component's "no matching results" message when the{' '}
                <code>menuPortalTarget</code>
                prop is set (e.g. within a{' '}
                <Link href="/components/dialogs/modal/code">Modal</Link>
                ).
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
